<template>
  <div>
    <div
      v-if="isProServicesLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 10vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem;"
        class="mr-1"
      />
    </div>
    <div v-else>
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <b-row
          v-for="(service, index) in forms"
          :key="index"
        >
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="services"
              rules="required"
            >
              <b-form-group
                label="Services"
                label-for="services"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="role"
                  v-model="service.service_id"
                  :reduce="(service) => service.id"
                  label="name"
                  :options="servicesOptions"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="réalisation"
              rules=""
            >
              <b-form-group
                label="Réalisation"
                label-for="works-picture"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-file
                  v-model="service.works_picture"
                  placeholder="Choissez les réalisations des pros"
                  multiple
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group
                label="Description"
                label-for="description"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea
                  id="description"
                  v-model="service.description"
                  placeholder="Textarea"
                  rows="3"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="3"
            offset-md="3"
            class="mt-4"
          >
            <b-button
              variant="danger"
              :disabled="forms.length == 1"
              @click="removeItem(index)"
            >
              Supprimer
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-4 mb-4">
          <b-col md="6">
            <b-button
              variant="primary"
              @click="repeateAgain"
            >
              Ajouter un autre service
            </b-button>
          </b-col>
          <b-col
            md="3"
            offset-md="3"
          >
            <b-button
              variant="primary"
              :disabled="isUpdateProLoading"
              @click="applyUpdateProfessionalAction"
            >
              {{ isUpdateProLoading ? "Chargement..." : "Enregistrer" }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  // BForm,
  BFormGroup,
  // BFormInput,
  BFormTextarea,
  // BFormRadioGroup,
  BFormFile,
  // BFormCheckboxGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    // BForm,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    // BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BFormRadioGroup,
    // BFormCheckboxGroup,
    // ToastificationContent,
    BButton,
  },
  props: {
    proData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      forms: [],
      isUpdateProLoading: false,
      isProServicesLoading: true,
      servicesOptions: [],
      payload: {
        services_id: [],
        descriptions: [],
        works_picture: [],
      },
    }
  },
  computed: {
    ...mapGetters('services', ['getServicesWithoutPagination']),
  },
  watch: {
    getServicesWithoutPagination(newVal) {
      newVal.forEach(element => {
        this.servicesOptions.push({
          id: element.id,
          name: element.name,
        })
        this.isProServicesLoading = false
      })
    },
  },
  mounted() {
    if (this.getServicesWithoutPagination.length != 0) {
      this.getServicesWithoutPagination.forEach(element => {
        this.servicesOptions.push({
          id: element.id,
          name: element.name,
        })
        this.isProServicesLoading = false
      })
    }
    this.$props.proData.services.forEach(el => {
      console.log(el)
      this.forms.push({
        service_id: el.pivot.service_id,
        description: el.pivot.description,
        works_picture: [],
      })
    })
  },
  methods: {
    ...mapActions('professionals', [
      'updateProfessionalServiceAction',
      'getProfessionalsAction',
    ]),

    applyUpdateProfessionalAction() {
      this.isUpdateProLoading = true
      this.forms.forEach(element => {
        this.payload.services_id.push(element.service_id)
        this.payload.descriptions.push(element.description)
        this.payload.works_picture.push(element.works_picture)
      })
      this.updateProfessionalServiceAction({
        payload: this.payload,
        id: this.$props.proData.id,
      })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getProfessionalsAction().then(() => {
            this.isUpdateProLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Service du pro modifié',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.payload = {
              services_id: [],
              descriptions: [],
              works_picture: [],
            }
          })
          this.$store.commit('professionals/SET_PRO_DETAILS', null)
          this.$router.push(`/professionals/${this.proData.id}/details`)
        })
        .catch(error => {
          this.isUpdateProLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    repeateAgain() {
      this.forms.push({
        service_id: '',
        description: '',
        works_picture: [],
      })
    },
    removeItem(index) {
      this.forms.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
