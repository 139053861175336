<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="proData.profile_image"
          :text="avatarText(proData.full_name)"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ proData.full_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.$el.click()"
        >
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif, .jpeg"
            :hidden="true"
            plain
            @change="applyChangeProProfileImageAction"
          />
          <div v-if="isProfileImageLoading">
            <span> Chargement ... </span>
            <b-spinner small />
          </div>
          <span v-else>Modifier la photo</span>
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Lieu"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="lieu"
              rules="required"
            >
              <b-form-input
                id="address"
                v-model="proData.address"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Entrez l'adresse du pro"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Prénom & Nom"
            label-for="full-name"
          >
            <validation-provider
              #default="{ errors }"
              name=" prenom & nom "
              rules="required"
            >
              <b-form-input
                id="full-name"
                v-model="proData.full_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Entrez le prenom suivi du nom du pro"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Numéro de téléphone"
            rules="required"
          >
            <b-form-group
              label="Numéro de téléphone"
              label-for="phone"
            >
              <b-input-group>
                <b-input-group-prepend
                  is-text
                  :class="errors[0] ? 'border-danger' : ''"
                >
                  BJ (+229)
                </b-input-group-prepend>
                <cleave
                  id="phone"
                  v-model="phone"
                  class="form-control"
                  :class="errors[0] ? 'border-danger' : ''"
                  :raw="false"
                  :options="options.phone"
                  placeholder="12 34 56 78"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Adresse email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="email"
            >
              <b-form-input
                id="email"
                v-model="proData.email"
                type="email"
                :state="errors.length > 0 ? false : null"
                placeholder="Entrez l'adresse email du pro"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="isProProfileInfoLoading"
      @click="applyChangeProProfileInfoAction()"
    >
      <div v-if="isProProfileInfoLoading">
        <span> Chargement ... </span>
        <b-spinner small />
      </div>
      <span v-else>Modifier les infos</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BSpinner,
  // BTable,
  // BCard,
  BFormFile,
  // BCardHeader,
  // BCardTitle,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { required, email/* , beninPhoneNumberFormatRule */ } from '@validations'

import { ValidationProvider/* , ValidationObserver */ } from 'vee-validate'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    // ValidationObserver,
    ValidationProvider,
    BMedia,
    BFormFile,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    BForm,
    BSpinner,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    // vSelect,
  },
  props: {
    proData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isProfileImageLoading: false,
      isProProfileInfoLoading: false,
      required,
      email,
      profileFile: '',
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ],
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      phone: '',

      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
      },

      avatarText,
    }
  },
  computed: {
    phoneNumber() {
      return this.phone.replace(/\s/g, '')
    },
  },
  created() {
    this.phone = this.$props.proData.phone_number.replace('229', '')
  },
  mounted() {
    this.configProAddressField()
  },
  methods: {
    ...mapActions('auth', [
      'changeProProfileImageAction',
      'changeProProfileInfoAction',
    ]),
    ...mapActions('professionals', ['getProfessionalsAction']),
    configProAddressField() {
      const input = document.getElementById('address')
      const options = {
        types: ['establishment'],
        componentRestrictions: { country: ['BJ'] },
      }
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options)

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        this.$props.proData.address = place.name
      })
    },
    applyChangeProProfileImageAction() {
      // eslint-disable-next-line prefer-destructuring
      this.profileFile = this.$refs.refInputEl.$el.files[0]
      this.url = URL.createObjectURL(this.profileFile)
      this.$props.proData.profile_image = this.url
      const payload = {
        id: this.$props.proData.id,
        profile_image: this.profileFile,
      }
      this.isProfileImageLoading = true
      this.changeProProfileImageAction(payload)
        .then(response => {
          console.log(response.data)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Photo de profil changée avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.getProfessionalsAction()
          this.isProfileImageLoading = false
        })
        .catch(error => {
          this.isProfileImageLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyChangeProProfileInfoAction() {
      this.isProProfileInfoLoading = true
      const payload = {
        id: this.$props.proData.id,
        full_name: this.$props.proData.full_name,
        address: this.$props.proData.address,
        email:
          this.$props.proData.email == null ? '' : this.$props.proData.email,
        phone_number: `229${this.phoneNumber}`,
      }
      this.changeProProfileInfoAction(payload)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Donnée  de profil changée avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
    
          this.getProfessionalsAction()
          this.isProProfileInfoLoading = false
          this.$store.commit("professionals/SET_PRO_DETAILS",null);
          this.$router.push(`/professionals/${response.data.id}/details`)
        })
        .catch(() => {
          this.isProProfileInfoLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertIcon',
                text: 'Erreur!, merci de réesayer',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
