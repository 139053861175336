<template>
  <div>
    <b-breadcrumb class="mb-2 ">
            <b-breadcrumb-item to="/professionals" >Liste des pros</b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'admin-pros-details', params: { id: this.$route.params.id } }">Détails</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Modifier</b-breadcrumb-item>
        </b-breadcrumb>
    <b-card>
      <div
      v-if="isLoadingProDetails"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 30vh"
    >
      <b-spinner variant="info" style="width: 3rem; height: 3rem;" class="mr-1"
      />
    </div>
      <b-tabs
      v-else
        pills
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Informations personnelle</span>
          </template>
          <pro-edit-tab-infos
            :pro-data="proData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="BriefcaseIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Services du pro</span>
          </template>
          <pro-edit-tab-services
            :pro-data="proData"
            class="mt-2 pt-75"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
 BSpinner,
 BBreadcrumb,
 BBreadcrumbItem
  // BAlert,
  // BLink,
  // BRow,
  // BFormSelect,
  // BCol,
  // BFormGroup,
  // BOverlay,
  // BProgress,
  // BButton,
  // BFormTextarea,
  // BFormInput,
  // BFormInvalidFeedback,
  // BFormFile,
} from 'bootstrap-vue'
import ProEditTabInfos from './ProEditTabInfos.vue'
import ProEditTabServices from './ProEditTabServices.vue'
import { mapActions, mapGetters } from 'vuex'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
   BSpinner,
    // BAlert,
    // BLink,
    // BRow,
    // BFormSelect,
    // BCol,
    // BFormGroup,
    // BOverlay,
    // BProgress,
    // BButton,
    // BFormTextarea,
    // BFormInput,
    // BFormInvalidFeedback,
    // BFormFile,
    ProEditTabInfos,
    ProEditTabServices,
    BBreadcrumb,
    BBreadcrumbItem
  },
  data() {
    return {
      proData: {},
      isLoadingProDetails:false
    }
  },

  computed: {
      ...mapGetters("professionals", ["getProDetails"]),
  },
  created() {
    if(this.getProDetails == null ) 
    {
      this.applyGetProDetailsAction()
    }
    else {
        this.proData = this.getProDetails
    }
  },
  methods: {
      ...mapActions('professionals', [
      'getProfessionalsDetailsAction',
    ]),
        
  applyGetProDetailsAction() {
    this.isLoadingProDetails = true
      this.getProfessionalsDetailsAction(this.$route.params.id).then(response => {
        this.isLoadingProDetails = false
        this.proData = response.data
      }).catch(error => {
        this.isLoadingProDetails = false
        console.log(error)
      })
    },

  }
}
</script>

<style>
</style>
